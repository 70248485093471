import React from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Translation from 'components/data/Translation';
import FeedTypeIcon from 'components/feed-management/FeedTypeIcon';
import Button from 'components/ui-components-v2/Button';
import { FeedTableItem } from '../types/types';

import '../styles/feed-table-row.scss';

interface Props {
    item: FeedTableItem;
    onSelectFeed: (datasetId: string, feedId: string) => void;
}

const FeedTableRow = ({ item, onSelectFeed }: Props) => {
    // Hide feed items with no items.
    if (item.feedItemsCount === 0) {
        return null;
    }

    return (
        <TableRow>
            <TableCell>
                <FeedTypeIcon type={item.feedType} />
            </TableCell>
            <TableCell>
                <div className="feed-table-row__cell__description">
                    <span className="feed-table-row__cell__description__name">{item.feedName}</span>
                    <a href={item.feedUrl} className="feed-table-row__cell__description__url" target="_blank" rel="noreferrer">
                        {item.feedUrl}
                    </a>
                </div>
            </TableCell>
            <TableCell>
                <div className="feed-table-row__cell__description__item-count">
                    <span className="feed-table-row__cell__description__item-count__number">{item.feedItemsCount}</span>
                </div>
            </TableCell>
            <TableCell>
                <Button onClick={() => onSelectFeed(item.datasetId, item.feedId)} data-cy="addFeedDialog-addFeedRow-button">
                    {Translation.get('feedTable.select', 'feed-management')}
                </Button>
            </TableCell>
        </TableRow>
    );
};

export default FeedTableRow;
