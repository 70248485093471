import React, { useEffect } from 'react';
import DataSetType from 'types/dataset.type';
import { DataSetManager } from 'components/editor-blocks/DataSetManager';
import User from 'components/data/User';
import FeedHelpers from 'components/data/FeedManagementHelpers';
import ComponentStore from 'components/data/ComponentStore';
import Dialog from 'components/ui-components/Dialog';
import Translation from 'components/data/Translation';

interface Props {
    dataSet?: DataSetType;
    onClose: () => void;
}

const DataSetPreviewDialog: React.FC<Props> = ({ dataSet, onClose }) => {
    useEffect(() => {
        if (User.hasRight('feedManagement')) {
            FeedHelpers.loadData();

            if (dataSet) ComponentStore.setData('FeedManager', { dataSet });
        }
    }, []);

    return (
        <Dialog open maxWidth="lg" fullWidth onClose={onClose} title={Translation.get('dataSetPreviewDialog.feedPreview', 'feed-management')}>
            <DataSetManager displayType="list" showFeedItem={false} onSetHasContent={() => null} showRowActions={false} />;
        </Dialog>
    );
};

export default DataSetPreviewDialog;
